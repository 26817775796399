@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.first {
  display: flex;
  align-items: center;
  opacity: 0;
}

.second {
  display: grid;
  grid-template-columns: auto auto auto;
  place-content: center;
  place-items: center;
}

.secondItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 100%;
  text-align: center;
}

.secondItem a {
  position: absolute;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  transition: 0.2s ease;
}

.secondItem a:hover {
  color: rgba(0, 0, 0, 1);
}

.secondItemActive {
  color: #000 !important;
  font-weight: 600 !important;
}

.secondLogo {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 200px;
  max-height: 32px;
  margin: 0;
  animation: fadeIn 200ms ease-in-out forwards;
}

.logoLeft {
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 216px;
  max-height: 30px;
  margin: 0;
  margin-right: auto;
  margin-bottom: -16px;
  color: white;
}

.logoLeft path:last-of-type {
  display: none;
}

.navLeft {
  margin-right: auto;
  opacity: 0;
  animation: fadeIn 200ms ease-in-out forwards;
}

.third {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 222px;
  margin: auto 0;
  color: #000;
}

.thirdRight {
  justify-content: normal;
  width: fit-content;
  color: #fff;
  animation: fadeIn 200ms ease-in-out forwards;
}

.header {
  position: relative;
  z-index: 500;
  display: none;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 32px);
  height: var(--navigation-height);
  margin: 0;
  padding: 0;
  color: #000 !important;
  font-weight: 500;
  font-size: 1rem;
  font-family: var(--fonts-sans);
  letter-spacing: normal;
  user-select: none;
  touch-action: manipulation;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
}

@media screen and (min-width: 992px) {
  .header {
    display: flex;
  }
}

.invert > div > summary > svg > path {
  fill: white;
}

.forCreatorsBtn {
  width: 132px !important;
}

.forCreatorsBtn > a,
.forCreatorsBtn > button {
  width: 132px !important;
}

.btnWrapper {
  position: relative;
  height: 30px;
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: 14px;
  line-height: 1;
  outline: 0;
  resize: none;
}

.btnWrapper > a,
.btnWrapper > button {
  box-sizing: inherit;
  direction: ltr;
  font-synthesis: none;
  max-width: 100%;
  padding: 12px 18px;
  border: none;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  transition: 0.2s ease;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  height: 30px;
  margin: 0;
  border-radius: 32px;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  font-family: var(--fonts-sans);
  line-height: 14px;
  letter-spacing: normal;
  white-space: nowrap;
  box-shadow: none;
  user-select: none;
  touch-action: manipulation;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'rlig' 1, 'calt' 0;
  text-rendering: optimizeLegibility;
}



.navLogoLeft {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  display: inline-flex;
  align-items: center;
  max-width: 208px;
  max-height: var(--navigation-height);
}

.authMenu .btnWrapper {
  margin-right: 48px;
}

.navLogoLeftIcon {
  min-width: 206px;
  height: 36px;
  margin-bottom: -16px;
}

.navLogoLeftIcon path:last-of-type {
  display: none;
}

.navLogo {
  width: 100%;
  max-width: 100%;
  height: 40px;
  margin: auto;
  transition: 0.25s ease;
}
