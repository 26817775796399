.mobileHeader {
  z-index: 500;
  display: block;
  width: 1048px;
  margin: auto;
  font-weight: 600;
  font-size: 18px;
  font-family: var(--fonts-sans);
  letter-spacing: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

@media screen and (min-width: 992px) {
  .mobileHeader {
    display: none !important;
  }
}

.menuContent {
  top: var(--navigation-height);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  max-height: calc(100vh - var(--navigation-height));
  background-color: rgba(255, 255, 255, 0.72);
}

.menuContent .ul {
  width: 100%;
  height: calc(100vh - var(--navigation-height));
  padding: 12px 24px 0 24px;
  background-color: rgba(237, 236, 236, 0.75);
  backdrop-filter: blur(30px);
}

.menuContent .ul li {
  display: flex;
  align-items: center;
}

.menuContent .ul li a,
.menuContent .ul li button {
  z-index: 9999;
  display: flex;
  align-items: center;
  height: 42px;
  margin: 4px 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.8);
  transition: 0.2s ease;
}

.menuContent .ul li a:active,
.menuContent .ul li button:active,
.menuContent .ul li a:focus,
.menuContent .ul li button:focus,
.menuContent .ul li a:hover,
.menuContent .ul li button:hover {
  color: rgba(0, 0, 0, 1);
}

.activeItem {
  color: #000 !important;
  font-weight: 700 !important;
}

.mobileMainContent {
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--navigation-height);
  margin-right: auto;
  padding: 0 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background-color: rgba(255, 255, 255, 0.72);
}

.noBorderItem {
  border-bottom: none !important;
}

.toggleBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: #000 0;
  -webkit-touch-callout: none;
}

.toggleBtn::after {
  content: '';
  position: absolute;
  left: -14px;
  height: 50px;
  width: 50px;
  top: -14px;
}

.toggleBtn svg {
  width: 24px;
  height: 24px;
  color: black;
}

.toggleBtn svg path {
  transform-origin: 50% 50%;
  transition: transform 0.15s ease;
}

.toggleBtnOpen > svg > path:first-child {
  transform: rotate(45deg) translateY(5px);
}

.toggleBtnOpen > svg > path:last-child {
  transform: rotate(-45deg) translateY(-5px);
}

.logoButton {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 152px;
  max-height: 32px;
  color: var(--secondary-color);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

.logoButton svg {
  position: absolute;
  top: -10px;
  left: -12px;
  height: 29px;
  margin: auto;
}

.menuItemButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 16px 12px;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  background-color: #000;
  cursor: pointer;
  transition: 0.2s ease;
}

.menuItemButton:active,
.menuItemButton:focus,
.menuItemButton:hover {
  background-color: #222;
  box-shadow: none;
}

.existingAccount {
  margin-top: 24px;
  border-bottom: none !important;
  font-size: 14px;
  text-align: center;
}

.existingAccount a {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 4px !important;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  letter-spacing: normal;
  transition: 0.2s ease;
}

.existingAccount a:hover {
  color: rgba(0, 0, 0, 1);
}

.profileItem {
  display: flex;
  align-items: center;
  padding: 16px 0 8px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.profileItem img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.profileItem p {
  margin: 0;
  padding: 0;
  padding: 0 4px 0 12px !important;
  color: #000;
}

.btnWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.searchBtn {
  margin-left: auto;
  margin-right: 10px;
}
