@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navRoot {
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: var(--navigation-height);
  margin-bottom: calc(-1 * var(--navigation-height));
  font-weight: 600;
  font-size: 15px;
  direction: ltr;
  letter-spacing: -0.022em;
  box-shadow: none;
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s;
}

.sticky {
  background-color: none;
  box-shadow: inset 0 -1px 0 0 #000 1a;
  backdrop-filter: saturate(180%) blur(5px);
  transition: box-shadow 0.2s ease 0s, background-color 0.2s ease 0s;
}

@media screen and (min-width: 992px) {
  .sticky {
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: saturate(180%) blur(5px);
  }
}

.absolute {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
}

.isOpen {
  background: rgba(255, 255, 255, 0.9) !important;
  box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1) !important;
  backdrop-filter: blur(20px) !important;
}

.isOpen .mobileHeader {
  background-color: rgba(237, 236, 236, 0.75) !important;
}

.isOpen .menuContent {
  backdrop-filter: blur(30px) !important;
}

.activeItem {
  color: #000 !important;
  font-weight: 700 !important;
}

.menuItemButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  padding: 16px 12px;
  border-radius: 5px;
  color: #fff !important;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  background-color: #000;
  cursor: pointer;
  transition: 0.2s ease;
}

.menuItemButton:active,
.menuItemButton:focus,
.menuItemButton:hover {
  background-color: #222;
  box-shadow: none;
}

.existingAccount {
  margin-top: 24px;
  border-bottom: none !important;
  font-size: 14px;
  text-align: center;
}

.existingAccount a {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 4px !important;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
  letter-spacing: normal;
  transition: 0.2s ease;
}

.existingAccount a:hover {
  color: rgba(0, 0, 0, 1);
}

.logoButton {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 152px;
  max-height: 32px;
  color: var(--secondary-color);
  text-decoration: none;
  cursor: pointer;
  transition: color 0.2s ease;
}

.logoButton svg {
  position: absolute;
  top: -9px;
  left: 16px;
  height: 32px;
  margin: auto;
}

.logoButton svg path:last-of-type {
  display: none;
}

.secondLogo {
  display: flex;
  justify-content: center;
  width: 216px;
  max-height: 30px;
  margin: 0;
  animation: fadeIn 200ms ease-in-out forwards;
}

.navLogo {
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: auto;
  transition: 0.25s ease;
}

.navLogo > path:last-of-type {
  opacity: 0.5;
  transition: 0.25s ease opacity;
}

.navLogoScroll > path:last-of-type {
  opacity: 0;
}

.logoLeft {
  display: flex;
  justify-content: center;
  width: 216px;
  max-height: 30px;
  margin: 0;
  margin-right: auto;
  margin-bottom: -16px;
  color: white;
}

.logoLeft path:last-of-type {
  display: none;
}

.secondItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 100%;
  text-align: center;
}

.secondItem a {
  position: absolute;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  transition: 0.2s ease;
}

.secondItem a:hover {
  color: rgba(0, 0, 0, 1);
}

.secondItemActive {
  color: #000 !important;
  font-weight: 600 !important;
}

.faq {
  margin-left: 16px;
  letter-spacing: normal;
  background: none;
  transition: 0.2s ease;
}

.faq:active,
.faq:focus,
.faq:hover {
  background: none;
  box-shadow: none;
}
