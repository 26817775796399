@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.details {
  position: absolute;
  right: 0;
  margin: auto 0;
}

.summary {
  width: 44px;
  height: 44px;
  margin-right: -7px;
  margin-left: auto;
  padding: 7px;
  border-radius: 90px;
  list-style: none;
  cursor: pointer;
  transition: 100ms box-shadow ease;
}

.summary ::-webkit-details-marker {
  display: none;
}

.summary>* {
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.1);
}

.summary:hover>* {
  box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.2);
}

.userImg {
  width: 30px;
  height: 30px;
  border-radius: 90px;
  opacity: 0;
  transition: opacity 200ms ease-in;
  animation: fadeIn 200ms ease-in-out forwards;
}

.userFallback {
  width: 30px;
  height: 30px;
  border-radius: 90px;
  opacity: 0;
  transition: 200ms ease-in-out;
  animation: fadeIn 200ms ease-in-out forwards;
}

.detailsMenu {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 100;
  display: none;
  width: 160px;
  height: 160px;
  border-radius: 5px;
  overflow: hidden;
  color: #000;
  list-style: none;
  background: #fff;
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.12));
}

.open {
  display: block;
}

.open>a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding-right: 12px;
  padding-left: 12px;
  color: #000;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  transition: 0.2s ease;
}

.open>a:hover {
  background-color: #f4f4f4;
}
